@import url('https://fonts.googleapis.com/css2?family=Raleway&display=swap');
body {
  font-family: 'Raleway', sans-serif;
  box-sizing: border-box;
}
* {
  margin: 0;
  padding: 0;
}
:root {
  --mainColor: #0274be;
  --mainColorLighter:  #026fe0;
  --secondaryColor: #14169c;
  --textColor: rgb(17, 17, 56);
  --backgroundColor: rgb(238, 252, 247);
}
h1,h2,h3 {
  color: var(--textColor);
}

.buttonstyle {
  background-color: #037fff!important;
  color: white;
}
p{
  line-height: 1.85714285714286;
  font-family: 'Raleway',sans-serif
}
.buttonStyle {
  align-items: center;
  border: 0;
  background-color: var(--mainColor);
  box-shadow: rgba(45, 35, 66, 0.4) 0 2px 4px,
    rgba(45, 35, 66, 0.3) 0 7px 13px -3px, rgba(58, 65, 111, 0.5) 0 -3px 0 inset;
  color: #fff;
  cursor: pointer;
  font-family: "JetBrains Mono", monospace;
  height: 48px;
  padding-left: 19px;
  padding-right: 19px;
  font-size: 18px;
  border-radius: 8px;
}



.buttonStyle:hover {
  box-shadow: rgba(45, 35, 66, 0.4) 0 4px 8px,
    rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #3c4fe0 0 -3px 0 inset;
  transform: translateY(-4px);
}