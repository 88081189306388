
.pageItem a{
    padding: .4rem;
    border: 1px solid rgb(144, 142, 142);
    text-align: center;
    background-color: var(--mainColor);
    color: white;
  }
  .pagination{
    justify-content: center;
    margin: 3rem;
    display: flex;
    flex-direction: row;
    list-style-type: none !important;
  }
  .filterContainer{
    justify-content: center !important;
  }