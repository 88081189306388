.adminBlogContainer{
/* background-color: var(--mainColor) !important; */
padding: 5% 30%;
background-image: url("https://img.freepik.com/free-photo/abstract-luxury-gradient-blue-background-smooth-dark-blue-with-black-vignette-studio-banner_1258-54368.jpg?w=900&t=st=1683715321~exp=1683715921~hmac=2ff8f78becc3aa18ed4d9330590ad84baeba5fe97689d71488e62c3d7145250b");
background-repeat: no-repeat;
background-size: cover;
}
.adminBlogForm{
    background-color:white;
   
    text-align: center !important;
    padding: 15%;
    border-radius: 5%;
    line-height: 4rem;
}
.BlogFormInput{
    width: 87%;
    padding: 1rem;
    border: 1px solid gray;
    border-radius: 5px;
    color: black;
}
.adminBlogForm button{
    width: 100%;
    height: 2.5rem;
}

@media screen and (max-width:500px) {
    .adminBlogContainer{
        padding: 10% 10%;    
        }
}