.visionContainerHome {
  padding: 0rem 2rem;
}

img {
  display: block;
  width: 100% !important;
  height: auto;
}

.visionContainerHome p {
  line-height: 1.8rem;
  text-align: left;
}
@media screen and (max-width: 800px) {
  .visionImg{
    height:30rem  !important;
  }
}
