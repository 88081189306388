
.blogs{
    background-color: var(--backgroundColor);
    padding: 2rem;
}
.blogGrid{
    border:1.2px solid var(--mainColor);
    width: 80%;
    padding: 1rem;
    border-radius: 15px;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
    
}
.blogGrid img{
/* width: 80%; */
border-top-right-radius: 15px;
border-top-left-radius: 15px;
}
.blogGrid p{
/* width: 80%; */
text-align: justify;
}
.blogs h2{
    padding: 3rem;
    text-align: center;
}
.blogsBanner{
    background-image: url('https://www.shutterstock.com/image-photo/technology-concept-banner-blog-260nw-551729392.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    text-align: center;
    color: white !important;
    height: 18rem;
    
}

.blogsContainer1{
    padding-left: 8%;
    padding-top: 4%;
}
.blog_container{
    justify-content: center !important;
}
