header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 90px;
  color: var(--mainColor);
}
.header span {
  margin: 1.7rem;
}
.header span:hover {
  cursor: pointer;
  color: var(--textColor);
}

.nav-close-btn {
  margin-left: 39rem;
}

header img {
  height:5rem;
  margin-left: 1.2rem;
}

a {
  text-decoration: none;
}
.headerMain{
  display: flex;
}
.headerMain span{
  color: var(--mainColor) !important;
}
@media screen and (max-width: 768px) {
  header .nav-btn {
    display: contents;
    background: var(--secondaryColor);
  }

  nav a {
    font-size: 1.5rem;
  }
}

.menu_item :last-child {
  padding-bottom: 0.5rem !important;
}

.menu_item {
  height: 5.5rem;
}

@media screen and (max-width: 430px) {
  .menu_item {
    height: 4.5rem;
  }
}

.header_open {
  margin: -10px !important;
}

.menu_item span{
  color: var(--mainColor) !important;
}
