.popupContainer{
    /* background-color: var(--mainColor); */
    background-image: url('https://img.freepik.com/free-photo/abstract-luxury-gradient-blue-background-smooth-dark-blue-with-black-vignette-studio-banner_1258-52379.jpg?w=1060&t=st=1683089591~exp=1683090191~hmac=8a9cc1fe6cc74625cfafc698baba01ae0279408ccd0e748382226fb3c83c9c9e');
    background-repeat: no-repeat;
    background-size: cover;
    text-align: center;
    padding: 2% ;
    border-radius: 14px;
    width: 60%;
    margin: 1% 17%;
   
}
.popupContainer h2{
    color: white !important;
}

.PartnerForm{
    background-color: white;
    padding: 4% ;
    border-radius: 4px;
    margin: 2% 10%;
    text-align: center !important;
    
}
.inputField{
    width: 100%;

}
.partnerStyle{
    border: none;
    color: var(--mainColor);
    background-color: transparent;
    font-size: 1rem;
    font-family: 'Raleway', sans-serif;
}
.required{
    color: red;
    font-size: .7rem;
    margin-top: 2% !important;
    text-align: left;
}
@media screen and (max-width:350px){
    .popupContainer{
        margin: 5% 5%;
        width: 85%;
    }
    .PartnerForm{
        margin: 2% 4%;
        padding: 3% ;
    }
}
@media screen and (max-width:450px){
    .popupContainer{
        margin: 5% 10%;
        width: 75%;
    }
    .PartnerForm{
        margin: 2% 4%;
        padding: 3% ;
    }
}
@media screen and (max-width:1950px)and (min-width:1700px){
    .popupContainer{
        margin-top: 8%;
    }
    .PartnerForm{
        padding: 8% ;
    }

}

input[type="file"] {
    display: none;
  }
  .custom-file-upload {
    /* border: 1px solid #ccc; */
    cursor: pointer;
    color: #707070;
    font-size: 1rem;
    font-family: "Roboto", sans-serif;
    
  }

  .imageInput{
    border: 1px solid rgb(201, 200, 200) !important;
    padding: 1rem !important;
    padding-left: 0.5rem !important;
    border-radius: 5px;
    text-align: left;
    margin: 1.2% !important;
  }
  .file-name{
    color: black;
  }

.imageInputRegister{
    display: none !important;
}