.footer ul{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1.5rem;
    list-style-type: none;
}
.secondColumn li:hover, .firstColumn li:hover{
    cursor: pointer !important;
    
}
.footerMain img{
    height: 7rem;
}
.firstColumn li{
    color: white;
}
.subscribeButton{
    color:var(--mainColor);
    border: 1px solid var(--mainColor);
    padding: .3rem;
    border-radius: 4rem;
    font-size: .8rem;
}
.subscribeButton:hover{
    cursor: pointer;
}
.footerMain{
  
    display: grid;
    grid-template-columns: 11% 7% 25%;
    gap: 10%;
    padding: 3rem;
    background-color: var(--mainColor);
    color: white;
}
.footerMain  li{
    list-style-type: none;
    line-height: 2.5rem;
}
.subscribeField{
    padding: .8rem;
    width: 19rem;
}
.subscribeInputField{
    width: 100%;
}
@media screen and (max-width:950px) {
    .footerMain{
        grid-template-columns: 20% 12.2% 40%;
        gap: 15%;
    }
}
@media screen and (max-width:620px) {
    .footerMain{
        grid-template-columns: 18% 14% 50%;
        gap: 10%;
    }
}
@media screen and (max-width:540px) {
    .footerMain{
        grid-template-columns: 1fr ;
        gap: 2%;
    }
    .footerMain img{
        height: 8rem;
        width: 13rem !important;
    }
    .subscribeButton{

        padding: .2rem;
        border-radius: 4rem;
        font-size: .7rem;
    }
    .logo img{
        width: 55% !important;
    }

}
.subscribeInput{
    margin-left: -10px;
}