.eventCard {
  display: grid;
  grid-template-columns: 40% 60%;
  background-color: white;
  padding: 2rem;
  gap: 2%;
  margin: 3rem;
}
.eventImage img:hover {
  transform: scale(1.05);
}
.singleEvent {
  padding: 4rem;
}
.singleEvent img {
  width: 80%;
  height: 23rem !important;
  margin: 0 auto;
  border-top-right-radius: 15px;
  border-top-left-radius: 15px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
    rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}
.eventsPage {
  background-color: rgb(238, 252, 247);
  padding: 2rem;
}
.singleEventContainer {
  background-color: var(--backgroundColor);
  padding: 4rem;
  border-radius: 15px;
}
@media screen and (max-width: 830px) {
  .eventCard {
    display: grid;
    grid-template-columns: 1fr;
  }
  .singleEventContainer {
    padding: 1rem;
    border-radius: 0px;
  }
  .eventsPage {
    background-color: rgb(238, 252, 247);
    padding: 2rem 0rem;
  }
}
.eventImage{
  height: 20%;
}
@media screen and (max-width: 500px) {
  .singleEvent img {
    height: 13rem !important;
  }
  .eventImage img {
    height: 19rem;
  }
  .eventCard {
    padding: 1rem;
    margin: 1.5rem;
  }
}
.eventBanner {
  /* background-image: url("https://st2.depositphotos.com/1139310/5382/i/600/depositphotos_53820479-stock-photo-flat-long-shadow-icon-of.jpg"); */
  background-image: url("https://www.shutterstock.com/image-photo/businessman-pressing-button-on-touch-260nw-351006905.jpg");
  height: 22rem;
  background-repeat: no-repeat;
  background-size: cover;
}

.counterMain {
  display: flex;
  gap: 5%;
  background-color: #0093e9;
  background-image: linear-gradient(160deg, #0093e9 0%, #80d0c7 100%);
  border-top-right-radius: 15px;
  border-bottom-left-radius: 15px;
  color: white;
  width: fit-content;
  padding: 2% 10% 2% 3%;
  text-align: center;
}
.counterDaysDiv p {
  font-size: 0.8rem;
}
.counterDaysDiv h3 {
  color: white !important;
}
.dateDiv {
  display: flex;
  justify-content: space-between;
}
@media screen and (max-width: 500px) {
  .dateDiv {
    display: grid;
    grid-template-columns: 1fr;
    gap: 10%;
  }
  .counterMain {
    border-radius: 5px;
    padding-right: 25%;
  }
  .eventCard {
    padding-bottom: 15% !important;
  }
  .eventBanner{
    background-image: url("../../Assets/eventBannerBG.png");
  }
}
@media screen and (max-width: 800px) {
  .counterMain {
    border-radius: 5px;
  }
}
@media screen and (max-width: 1990px) and (min-width: 1700px) {
  .eventBanner {
    height: 30rem;
  }
  .eventContents {
    width: 95%;
  }
  .dateDiv {
    margin-top: 10%;
  }
}
