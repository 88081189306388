.partnersDiv {
  text-align: center;
  color: var(--textColor);
  justify-content: center;
  padding: 1.5rem;
  background-color: var(--backgroundColor);
}
.partnersImage {
  height: 90%;
  width: 90%;
}
.partnersDiv h2 {
  padding-bottom: 2rem;
}
.partnersDiv img{
    width: 55% !important;
    margin-left: 80%;
  }
.logos img {
  width: 65% !important;
  height: 65%;
}
.logos {
  display: grid;
  grid-template-columns: 34% 28%;
}
.w3r-triangle {
  width: 0;
  height: 0;
  border-top: 250px solid #373ccf;
  border-left: -0px solid transparent;
  border-right: 240px solid transparent;
}
/* .partnersDiv{
    background-image: url('../../Assets/partnerBG.png');
    background-size: cover;
    background-repeat: no-repeat;
  } */
  .partnersImage img{
    width: 55%!important;
    height: 80% !important;
  }
@media screen and (max-width: 500px) {
  .partnersDiv img {
    width: 10rem;
  }
  .logos img {
    width: 95% !important;
    height: 95%;
  }
  .logos {
   gap: 5%;
  }
}
