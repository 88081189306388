.bannerContent {
  padding: 17%;
}
.bannerContent h2 {
  font-family: "Raleway", sans-serif;
  font-weight: 200;
}
.bannerVideo video {
  height: 100% !important;
  width: 100%;
}
