@import url('https://fonts.googleapis.com/css2?family=Exo:wght@200;300;400&family=Playfair:wght@400;500;600;800&display=swap');
.about-contents{
    padding: 2rem 4rem;
    text-align: justify;

}
.aboutImage{

    width: 100% !important;
}
.swiperAboutContainer {
background-image: url("https://media.istockphoto.com/id/476423439/photo/abstract-bluebackground.jpg?b=1&s=170667a&w=0&k=20&c=c2l_t3CdTjB_9GrFmXFzU05oLYHcqBQX_CBTxVnRA6o=");
background-repeat: no-repeat;
background-size: cover;
padding: 6% 5%;
/* background-color: var(--mainColor); */
}
.aboutBanner h1{
    font-family: 'Playfair', serif !important;
    padding:0rem 4rem;
    font-size: 4.4rem;
}
.swiperAboutContainer  h1{
    color: white;
    padding:0rem 0rem 4rem;
}
.swiperAbout img{
    height: 20rem;
    
}

.aboutDiv2{
    display: grid;
    grid-template-columns: 45% 33%;
    gap: 12%;
}
.aboutDiv2Content h2{
   color: white;
}
.aboutDiv3{
    padding: 5rem 3rem;
    display: grid;
    grid-template-columns: 30% 30% 30%;
    gap: 5%;
    justify-content: center !important;
}
.aboutDiv3 p{
    text-align: justify;
}
.aboutDiv3 h2{
    text-align: center;
}
.vision,.mission,.values{
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
    padding: 1.5rem;
}
.MissionIcon{
justify-content: center;
align-items: center;
text-align: center;
color: var(--mainColor);
font-size: 4rem !important;
margin-left:30%;
padding: 1rem;
}
.aboutBanner{
    background-image: url("../../Assets/aboutPic.png");
    padding: 8rem 3rem;
    background-repeat: no-repeat;
    background-size: cover;
}
.team{
   display: grid;
   grid-template-columns: 15% 15% 15%;
   gap: 5%; 
   justify-content: center;
   padding: 4% 0%;
}
.team1 h3, .team1 p{
    text-align: center;
    padding-top: 5%;
}
.team img{
    background-color: white;
    border: 1px solid blue;
    padding: 0.5rem;
    border-radius: 50%;

    height: 12rem !important;
}
.team img:hover{
    background-color: var(--mainColor);
    transform: scale(1.14);
}
.teamGrid{
    background-color: var(--backgroundColor);
}
.teamGrid h1{
    text-align: center;
    padding-top: 3%;
    color: var(--mainColor);
}
.teamGrid h2{
    text-align: center;
}
.aboutDescription{
    text-align: left;
}
@media screen and (max-width:1950px)and (min-width:1700px){
    .aboutBanner{
        padding: 10rem 3rem;
    }
    .swiperAbout img{
        height: 25rem;
        
    }
    .team img{
        height: 17rem !important;
    }
    .vision,.mission,.values{
        padding: 2rem;
    }
    .MissionIcon{
        margin-left:40%;
        }
     .popupClose{
            transform: translate(48rem)!important;
        }
}
@media screen and (max-width:860px){
    .aboutDiv3{
        padding: 5rem 3rem;
        grid-template-columns: 1fr 1fr;
    }
    .popupClose{
        transform: translate(18rem)!important;
    }
}
@media screen and (max-width:600px){
    .aboutDiv3{
        padding: 5rem 1.5rem 11rem;
        grid-template-columns: 1fr;
    }
    .aboutDiv2{
        grid-template-columns: 50% ;
    }
    .aboutDiv1{
        padding-top:  0rem;
    }
    .swiperAbout,.aboutDiv2Content{
        width: 27rem;
        padding-left: 11%;
    }
    .aboutDiv2Content{
        padding-bottom: 40%;
    }
    .about-contents{
        padding: 1rem 2rem;
    }

}

@media screen and (max-width:550px){
    
    .swiperAbout,.aboutDiv2Content{
        width: 20rem;
    }
    .swiperAbout{
        width: 19rem !important;
        
    }
    .team{
        grid-template-columns:1fr;
        gap: 4%;
        padding-bottom: 50%;
    }
    .team img{
        width: 12rem !important;
        /* margin-left: 12%; */
     
    }
    .team1 img{
        margin-left: 22%;
    }
    .popupClose{
        transform: translate(8rem,-10rem)!important;
    }
    .aboutDiv2Content{
        padding-bottom: 70%;
        color: white;
    }
    .TeamContents{
        display: grid;
        grid-template-columns:1fr !important;
        padding: 10%;  
        text-align: center;
        padding-bottom: 10% !important;
    }
    .teamPopupImg img{
        margin-left: 13% !important;
        margin-top: 10%;
    }
    .aboutBanner h1{
        padding:0rem 0rem;
        font-size: 3.4rem;
    }
    .about-contents h2{
        text-align: left;
    }
}
@media screen and (max-width:400px){
    
    .swiperAbout,.aboutDiv2Content{
        width: 16rem;
    }
    .swiperAbout{
        width: 15rem !important;
        
    }

    .team1 img{
        margin-left: 18%;
    }
    .popupClose{
        transform: translate(6rem,-12rem)!important;
    }
    .aboutBanner h1{
        font-size: 2.8rem;
    }

}
.PopupDiv{
    margin: 2rem 15% ;
    background-color: var(--backgroundColor);
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}
.TeamContents{
    display: grid;
    grid-template-columns: 30% 40%;
    padding: 2%;  
}
.AboutTeam p{
    padding:4% ;
    text-align:left;

}
.TeamContents{
    background-image: url('https://img.freepik.com/free-vector/blue-curve-background_53876-113112.jpg?w=900&t=st=1682576711~exp=1682577311~hmac=41b6a360a04c0debdfa72f510ce779f1d31d57b39d5ac35506b1e3bda0987885');
    background-repeat: no-repeat;
    background-size: cover;
}
.teamPopupImg img{
margin-left: 8%;
    border-radius: 50%;
    width: 70% !important;
    height: 11rem !important;
}
.popupClose{
    transform: translate(35rem);
}
.aboutBanner h1{
    color: white;
}
.teamGrid{
    justify-content: center !important;
}
.AboutTeam ul{
    margin-left: 6%;
}