
.gallery {
  padding: 3rem;
  background-image: url("https://img.freepik.com/premium-photo/close-up-blue-football-jersey-clothing-fabric-texture-sports-wear-background-sport-clothing-fabric_620624-563.jpg?w=900");
  background-repeat: no-repeat;
  background-size: cover;
}
.gallery h1 {
  color: white;
  font-weight: 800;
}
.gallerySlides {
  background-color: white;
}
.gallerySlides p{
  padding:0rem 1rem 1rem;
}
.galleryApiImage img{
  width: 20rem !important;
}
.gallerySlides img{
  height: 20rem;
}
@media screen and (max-width:500px) {
  .gallery {
    padding: 1rem;
  }
}