.joinImage {
  background-image: url("https://www.cosmetologycoursesmumbai.com/img/Partner-with-us.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  height: 20rem !important;
}
.joinContents {
  display: grid;
  grid-template-columns: 40% 50%;
  margin: 4%;
  gap: 5%;
}
.partnerCard {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
    rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
  padding: 1.5rem;
  background-color: white;
}
.becomePartnerDiv3 {
  display: grid;
  grid-template-columns: 30% 30% 30%;
  padding: 4%;
  gap: 5%;
}
.becomePartnerDiv3 h2 {
  text-align: center;
}
@media screen and (max-width: 800px) {
  .becomePartnerDiv3 {
    grid-template-columns: 1fr 1fr;
    padding-bottom: 16% !important;
  }
  .joinContents {
    grid-template-columns: 1fr;
  }
  .partnerMerits h2 {
    padding-top: 10%;
    padding-bottom: 5% !important;
  }
}
@media screen and (max-width: 500px) {
  .becomePartnerDiv3 {
    grid-template-columns: 1fr;
    padding-bottom: 50% !important;
  }
  .joinContents {
    grid-template-columns: 1fr;
  }
  .partnerMerits h2 {
    padding-top: 20%;
    padding-bottom: 10% !important;
  }
  .partnerCard h2 {
    margin-top: -3rem !important;
    margin-bottom: -2rem;
  }
  .MissionIcon {
    padding-left: 9%;
  }
}
.joinContents p {
  text-align: justify;
}
