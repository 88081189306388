.training{
    background-color: rgb(238, 252, 247);
    padding: 2rem;
   
}
.trainingGrid{
    border:1.2px solid var(--mainColor);
    padding: 1rem;
    border-radius: 15px;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
}
.trainingGrid img{
border-top-right-radius: 15px;
border-top-left-radius: 15px;
}

.trainingGrid li{
    list-style-type: none;
    line-height: 2.5rem;
    
}

.training{
    display: grid;
    grid-template-columns: 30% 30% 30%;
    gap: 5%;
}
 .trainingGrid .buttonStyle{
text-align: center !important;
     margin-left: 25% !important; 
     margin: 2rem; 
     
 }

.trainingBanner{
    background-image: url("../../Assets/trainingbanner.png");
    padding: 8rem 3rem;
    background-repeat: no-repeat;
    background-size: cover;
}
.trainingBanner h1{
    padding-left: 9%;
    color: white !important;
    font-family: 'Playfair', serif !important;
    font-size: 4.5rem;
}

@media screen and (max-width:1990px)and(min-width:1700px) {

    .trainingBanner{
        height: 10rem;
    }

}
@media screen and (max-width:1160px) {
    .training{
      
        grid-template-columns: 33% 33% 33%;
        gap: 1%;
    }
}
@media screen and (max-width:1160px) {
    .training{
       padding-bottom: 20%;
        grid-template-columns: 1fr 1fr;
        gap: 8%;
    }
}
@media screen and (max-width:660px) {
    .training{
        padding-bottom: 30%;
        grid-template-columns: 1fr;
        gap: 4%;
    }
    .trainingGrid .buttonStyle{ 
        margin: 1rem; 
    }
    .trainingGrid ul{
        margin-left: 5%;
    }
    .viewMoreContainer{
        grid-template-columns: 1fr !important;
        padding-bottom: 20% !important;
    }
    .trainingBanner h1{
        justify-content: center !important;
    }
}
@media screen and (max-width:380px) {
    .training{
        padding:1rem;
        padding-bottom: 50%;
    }
    .trainingGrid .buttonStyle{ 
        margin: 0rem; 
        margin-left: 18% !important; 
    }
    .trainingGrid ul{
        margin-left: 1%;
    }
    
}
.trainingViewButton{
    display: flex;
    justify-content: space-between;
    margin: 1rem;
}
.trainingButtonStyle{
    background-color: var(--mainColor);
    border: none;
    color: white;
    padding: .7rem;
    border-radius: .5rem;
}
.viewButtonStyle{
    color: var(--mainColor);
    padding: .6rem;
    background-color: white;
    border: 1px solid var(--mainColor);
    border-radius: .5rem;
}

/* VIEW MORE */

.viewMoreContainer{
    padding: 3%;
    display: grid;
    grid-template-columns: 50% 40%;
    gap: 5%;
    background-color: var(--backgroundColor);
}
.viewMoreContainer img{
    border-radius: 5%;
}
.time{
    color: var(--mainColor);
}