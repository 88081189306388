.eventsimage img {
  width: 100%;
  height: 200px;
}
.eventsContainer {
  padding: 3rem;
  background-color: var(--mainColor);
  }
/* .cards {
  justify-content: center;
} */


.container {
  position: relative;
}

.eventsCard{
  background-color: white;
  margin: 1rem !important;
  padding: 5px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}
.eventsCard:hover{
  transform: scale(1.15);
}
.eventsContainer h1{
  color: white;
  font-weight: 800;
}

@media screen and (max-width:500px) {
  .eventsContainer {
    padding: 1rem;
    padding-left: 2.5rem;
  }
}