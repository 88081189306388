.counters {
  display: grid;
  grid-template-columns: 20% 20% 20%;
  gap :8%;
  justify-content: center;

text-align: center;
  padding: 0rem 0rem 7rem 0rem;
}
.conterOutsideDiv{
  background-image: url("https://img.freepik.com/premium-photo/close-up-blue-football-jersey-clothing-fabric-texture-sports-wear-background-sport-clothing-fabric_620624-563.jpg?w=900");
  background-repeat: no-repeat;
  background-size: cover;
}
.conterOutsideDiv h1{
  color: white;
  padding:  4rem;
 }
.counterIcon {
 background-color: var(--mainColor);
  color: white;
  font-size: 2.9rem !important;
  border-radius: 50%;
  padding: 1.9rem;
}
.counterStyle{
 color: var(--mainColor);
    font-size: 2.9rem;
   font-weight: 800;
}
.counterDiv{
  border-radius: 5%;
  background-color:white;
  padding: 1.6rem;
  box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;
}
.counterTitle{
  position: relative;
  top: 4rem;
}
.conterOutsideDiv h1{
  text-align: center;
}
@media screen and (max-width:500px) {
  .counters{
    grid-template-columns:1fr;
    gap: 10%;
  }
  .counterDiv{
    margin:0% 13%;
  }
  .conterOutsideDiv{
    padding-bottom: 20%;
  }
}
